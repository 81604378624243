import { Box } from "@mui/material";
import {
  AddRemoveButton,
  Body,
  Input,
  Select,
  Small,
  Spacer,
  SubTitle2,
} from "@vestaboard/installables";
import { useLayoutEffect, useState } from "react";
import { IChannelButton, IChannelButtons } from "../api";
import { makeVestaboardStyles } from '@vestaboard/installables/lib/hooks/useVestaboardStyles';


interface ISubscriptionPickerProps {
  number: 1 | 2 | 3;
  displayOptions: Array<{
    name: string;
    id: string;
    messageSetId?: string;
    installableId?: string;
  }>;
  selectedOptions?: IChannelButtons;
  setSelectedOptions: (options: IChannelButtons) => void;
}

const useStyles = makeVestaboardStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export const SubscriptionPicker = (props: ISubscriptionPickerProps) => {
  const classes = useStyles();
  const [text, setText] = useState<string>("");
  const [textLoaded, setTextLoaded] = useState<boolean>(false);
  const savedDisplayName =
    props.selectedOptions?.[`${props.number}`]?.displayName;
  useLayoutEffect(() => {
    if (!textLoaded && !!savedDisplayName) {
      setText(savedDisplayName || "");
      setTextLoaded(true);
    }
  }, [savedDisplayName, textLoaded]);

  const defaultPlaceholder = `Show me a message from ${props?.selectedOptions?.[props.number]?.name || 'this channel'}!`

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Box sx={classes.header}>
        <SubTitle2>Select Channel</SubTitle2>
        <Box
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Box
            style={{
              marginRight: 8,
            }}
          >
            <Body>Remove</Body>
          </Box>
          <AddRemoveButton
            add={false}
            onPress={() => {
              if (!props.selectedOptions) {
                return;
              }
              const channelsWithOutCurrentIndex = Object.keys(
                props.selectedOptions
              ).filter((key) => key !== `${props.number}`);
              const remainIngChannels = channelsWithOutCurrentIndex.reduce(
                (acc, key) => {
                  return {
                    ...acc,
                    [key]: props.selectedOptions
                      ? props.selectedOptions[key as "1" | "2" | "3"]
                      : undefined,
                  };
                },
                {}
              ) as IChannelButtons;
              const reducedChannels = Object.keys(remainIngChannels).reduce(
                (acc, key, index) => {
                  return {
                    ...acc,
                    [index + 1]: remainIngChannels[key as "1" | "2" | "3"],
                  };
                },
                {}
              ) as IChannelButtons;
              props.setSelectedOptions({
                ...reducedChannels,
              });
            }}
          />
        </Box>
      </Box>
      <Spacer size="small" />
      <Select
        label="Select one of your channels"
        options={props.displayOptions}
        value={
          props.selectedOptions
            ? props.selectedOptions?.[`${props.number}`]?.id || ""
            : ""
        }
        onValueChange={function (id: string): void {
          // get subscription via id, set text as displayName
          const selectedOption = props.displayOptions.find(
            (option) => option.id === id
          ) as IChannelButton | undefined;
          const previousSelection = props.selectedOptions || {};
          if (selectedOption) {
            const defaultButtonText = `Show me a message from ${selectedOption.name}!`
            setText(defaultButtonText)
            // @ts-ignore
            props.setSelectedOptions({
              ...previousSelection,
              [`${props.number}`]: {
                name: selectedOption.name,
                id: selectedOption.id,
                displayName: defaultButtonText,
                number: props.number,
                messageSetId: selectedOption?.messageSetId,
                installableId: selectedOption?.installableId,
              },
            });
          }
        }}
      />
      <Spacer size="small" />
      <Input
        value={text}
        placeholder={defaultPlaceholder}
        onValueChange={function (value: string): void {
          setText(value);
          const currentId = props.selectedOptions?.[`${props.number}`]?.id;
          if (currentId) {
            const selectedOption = props.displayOptions.find(
              (option) => option.id === currentId
            ) as IChannelButton;
            const previousSelection = props.selectedOptions || {};
            if (selectedOption) {
              // @ts-ignore
              props.setSelectedOptions({
                ...previousSelection,
                [`${props.number}`]: {
                  name: selectedOption.name,
                  id: selectedOption.id,
                  displayName: value,
                  number: props.number,
                  messageSetId: selectedOption?.messageSetId,
                  installableId: selectedOption?.installableId,
                },
              });
            }
          }
        }}
      />
      <Spacer size="small" />
      <Box sx={{
        textAlign: 'center'
      }}>
        <Small>Write the text that guests will see when presented with options</Small>
      </Box>
      <Spacer size="medium" />
      <Spacer size="medium" />
    </Box>
  );
};
