import { ISwatchValue } from "@vestaboard/installables";
import axios from "axios";
export const API_URL = "https://platform.vestaboard.com";

export interface IChannelButton {
  id: string;
  name: string;
  displayName: string;
  messageSetId?: string;
  installableId?: string;
}

export interface IChannelButtons {
  "1"?: IChannelButton;
  "2"?: IChannelButton;
  "3"?: IChannelButton;
}
export const getSubscriptionCreds = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  const data = response.data as any;
  const subscriptionId = data["subscription"]["id"];
  const apiKey = data["subscription"]["installation"]["apiCredential"]["key"];
  const apiSecret =
    data["subscription"]["installation"]["apiCredential"]["secret"];

  return {
    subscriptionId,
    apiKey,
    apiSecret,
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export const makeGameRoom = async (subscriptionId: string, customCode = "") => {
  const response = await axios.post(`/api/game-room`, {
    subscriptionId,
    customCode,
  });

  return response.data as {
    gameCode: string;
    gameUrl: string;
  };
};

export const saveSubscriptionSettings = async (settings: {
  subscriptionId: string;
  subscriptionConfigToken: string;
  intervalMinutes: string;
  message: string;
  messageColor: ISwatchValue;
  allowFreeCompose: boolean;
  promoteUrl: boolean;
  boardId: string;
  boardName: string;
  gameCode: string;
  gameUrl: string;
  customCode: string;
  channelButtons?: IChannelButtons;
  captureInfo: boolean;
  queueSeconds: string;
}) => {
  const response = await axios.post("/api/subscription", {
    ...settings,
  });
  const data = response?.data as {
    subscriptionRow: any;
  };
  return data;
};

export const checkSetupComplete = async (
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.post("/api/checksetup", {
    subscriptionId,
    subscriptionConfigToken,
  });
  const data = response?.data as {
    setupComplete: boolean;
    message: string;
    intervalMinutes: string;
    queueSeconds: string;
    captureInfo: boolean;
    messageColor: ISwatchValue;
    customCode: string;
    gameCode: string;
    channelButtons?: IChannelButtons;
    allowFreeCompose: boolean;
    promoteUrl: boolean;
  };
  return data;
};

export const displayNow = async (
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  await axios.post("/api/display-now", {
    subscriptionId,
    subscriptionConfigToken,
  });
};

export interface IMessageSetIndex {
  id: string;
  title: string;
  description: string;
  installable: string;
  messageSet: {
    id: string;
  };
}

export const getSubscriptionsForBoard = async (
  subscriptionmId: string,
  subscriptionConfigToken: string,
  boardId: string
) => {
  const response = await axios.get(
    `/api/${boardId}/subscriptions?subscriptionmId=${subscriptionmId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return response.data as {
    subscriptions: Array<{
      id: string;
      name: string;
      installableId: string;
      messageSetId?: string;
    }>;
  };
};

export const getMessageSetIndex = async () => {
  const response = await axios.get(`${API_URL}/v3.0/message-set-index`);
  return response.data as Array<IMessageSetIndex>;
};

export const sendMessage = async (
  gameCode: string,
  message: string,
  playerName: string,
  userSessionUUID: string,
  channel?: IChannelButton,
  email?: string
) => {
  const resp = await axios.post("/api/send-message", {
    gameCode,
    message,
    playerName,
    channel,
    userSessionUUID,
    email
  })
  return resp.data as {
    count: number;
  }
};

export const captureEmail = async (
  email: string,
  gameCode: string,
  userSessionUUID: string,
  playerName?: string,
) => {
  return await axios.post("/api/capture-email", {
    email,
    playerName,
    userSessionUUID,
    gameCode,
  });
};

export const getUsageData = async (
  subscriptionmId: string,
  subscriptionConfigToken: string
) => {
  const resp = await axios.get(
    `/api/usage-data?subscriptionId=${subscriptionmId}`,
    {
      headers: {
        "X-Vestaboard-Subscription-Config-Token": subscriptionConfigToken,
      },
    }
  );
  return resp.data as Array<{
    email: string;
    playerName: string;
    gameCode: string;
    lastUpdated: string;
    messageCount: number;
    userSessionUUID: string;
  }>;
};

export const getPublicSettings = async (gameCode: string) => {
  const resp = await axios.get(`/api/public-settings/${gameCode}`, {});
  return resp.data as {
    gameCode: string;
    captureInfo: boolean;
    allowFreeCompose: boolean;
    channelButtons?: IChannelButtons;
  };
};


export const getQuietHoursData = async (subscriptionId: string) => {
  const resp = await axios.get(`/api/subscription/${subscriptionId}/quiethours`, {});
  return resp.data as {
    formattedQuietHours: string;
    isQuietHours: boolean;
  };
}


export const getQuietHoursDataForGame = async (gameCode: string) => {
  const resp = await axios.get(`/api/game/${gameCode}/quiethours`, {});
  return resp.data as {
    formattedQuietHours: string;
    isQuietHours: boolean;
  };
}