import { Box } from "@mui/material";

export const Logo = () => {
    //  #181919 
    return  <Box
    style={{
        backgroundColor: '#181919',
        width: '100%',
        height: '54px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    ><a href="https://vestaboard.com"><img src="/vblogo.webp" height={15} alt={'vestaboard-logo'}/></a></Box>
}