import { VestaboardContextProvider } from "@vestaboard/installables/lib/hooks";
import { Settings } from "./pages/Settings";
import { Theme } from "@vestaboard/installables/lib/components/Theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastProvider } from "@vestaboard/installables";
import { GuestSend } from "./pages/GuestSend";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <VestaboardContextProvider>
        <Settings />
      </VestaboardContextProvider>
    ),
  },
  {
    path: "/guest-message/:gameCode/:playerName",
    element: <GuestSend />,
  },
]);

const App = () => {
  return (
    <>
      <Theme>
        <ToastProvider offset={0}>
          <RouterProvider router={router} />
        </ToastProvider>
      </Theme>
    </>
  );
};

export default App;
