import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Body,
  Medium,
  SubTitle,
  SubTitle2,
} from "@vestaboard/installables/lib/components/Typography";
import {
  Button,
  Divider,
  Input,
  Modal,
  NotificationBanner,
  Spacer,
  colors,
  useToasts,
} from "@vestaboard/installables";
import { Box } from "@mui/material";
import { makeVestaboardStyles } from '@vestaboard/installables/lib/hooks/useVestaboardStyles';

import {
  IChannelButton,
  IChannelButtons,
  captureEmail,
  getPublicSettings,
  sendMessage,
} from "../api";
import useLocalStorage from "use-local-storage";
import { Logo } from "../components/Logo";

import Confetti from "react-dom-confetti";
import { useIsQuietHours } from "../hooks/useQuietHours";

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  // perspective: "500px",
  colors: [
    "poppyRed",
    "orange",
    "yellow",
    "green",
    "parisBlue",
    "violet",
    "tundra",
  ].map(
    (color: any) =>
      // @ts-ignore
      colors[color]
  ),
};
console.log("colors", config.colors);

// lightGrey: string;
// smoke: string;
// grey: string;
// woodSmoke: string;
// mariner: string;
// lightBlue: string;
// white: string;
// oldBrick: string;
// espresso: string;
// shark: string;
// nevada: string;
// alto: string;
// black: string;
// darkBlack: string;
// blank: string;
// poppyRed: string;
// orange: string;
// yellow: string;
// green: string;
// parisBlue: string;
// violet: string;
// mineShaft: string;
// tundra: string;
// shuttleGray: string;
// darkBlue: string;

const useStyles = makeVestaboardStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: '24px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "90vh",
    padding: '16px',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  descriptionContainer: {
    textAlign: "left",
    width: "100%",
    maxWidth: 600,
  },
});

export const GuestSend = () => {
  const classes = useStyles();
  const { gameCode, playerName } = useParams();

  const [sending, setSending] = useState<boolean>(false);
  const [confetti, setConfetti] = useState<boolean>(false);

  const [userSessionUUID] = useLocalStorage("userSessionUUID", uuidv4());

  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useLocalStorage("email", "");
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [allowFreeCompose, setAllowFreeCompose] = useState<boolean>(false);
  const [freeComposeOpen, setFreeComposeOpen] = useState(false);
  const [channelButtons, setChannelButtons] = useState<IChannelButtons>();
  const { isQuietHours, formattedQuietHours } = useIsQuietHours({
    gameCode
  });
  const { addToast } = useToasts();

  useEffect(() => {
    gameCode &&
      getPublicSettings(gameCode).then((settings) => {
        setOpenEmailModal(settings.captureInfo);
        setAllowFreeCompose(settings.allowFreeCompose);
        settings.channelButtons && setChannelButtons(settings.channelButtons);
      });
  }, [gameCode]);

  return (
    <>
      <Logo />
      <Box sx={classes.content}>
        <Box sx={classes.descriptionContainer}>
          <NotificationBanner
            text={formattedQuietHours}
            visible={isQuietHours}
          />
          <Spacer size={"medium"} />
          <SubTitle>Welcome, {playerName}!</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Pick an option below to send a message to the Vestaboard as{" "}
            {playerName}.
          </Body>
          <Spacer size={"large"} />
          <Divider />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Confetti active={confetti} config={{ ...config }} />
          </Box>
        </Box>
        <Box sx={classes.section}>
          <Spacer size={"medium"} />
          <>
            {channelButtons &&
              Object.keys(channelButtons).map((button) =>
                // @ts-ignore
                channelButtons?.[button].displayName ? (
                  <>
                    <Button
                      buttonType="white"
                      key={`channel-button-${button}`}
                      disabled={sending}
                      onClick={async () => {
                        if (isQuietHours) {
                          return addToast(formattedQuietHours, {
                            appearance: "error",
                          });
                        }
                        // @ts-ignore
                        const channel = channelButtons?.[
                          button
                        ] as IChannelButton;
                        setSending(true);
                        gameCode &&
                          playerName &&
                          (await sendMessage(
                            gameCode,
                            "",
                            playerName,
                            userSessionUUID,
                            channel,
                            email
                          )
                            .then(({ count }) => {
                              setMessage("");
                              const success =
                                count === 0
                                  ? `Message up next`
                                  : `Message queued after ${count} message${
                                      count > 1 ? "s" : ""
                                    }`;
                              addToast(success, {
                                appearance: "success",
                              });
                              setConfetti(true);
                            })
                            .catch((error) => {
                              console.log(error, Object.keys(error));
                              addToast(error.response.data.error, {
                                appearance: "error",
                              });
                            }));
                        setSending(false);
                        setConfetti(false);
                      }}
                    >
                      {/* @ts-ignore */}
                      {channelButtons?.[button].displayName}
                    </Button>
                    <Spacer size={"medium"} />
                  </>
                ) : (
                  <></>
                )
              )}
          </>
          {allowFreeCompose && (
            <Button
              onClick={() => {
                setFreeComposeOpen(!freeComposeOpen);
              }}
              buttonType="white"
              key={`channel-button-open-free-compose`}
            >
              {freeComposeOpen ? "Close Compose" : "Compose a message"}
            </Button>
          )}
          {freeComposeOpen && (
            <>
              <Spacer size={"medium"} />
              <Input
                placeholder="Send a message!"
                onValueChange={(text) => {
                  const chunks = text.match(/.{1,22}(\s|$)/g);
                  const newLines = text.split("\n");
                  const chunkCount = chunks?.length || 0;
                  const canSubmit =
                    chunkCount <= 6 &&
                    newLines.length <= 6 &&
                    text.length <= 132;
                  canSubmit
                    ? setMessage(text)
                    : addToast(`Message too long`, { appearance: "error" });
                }}
                value={message}
                max="132"
                multiline={true}
              ></Input>
              <Spacer size={"medium"} />
              <Button
                buttonType="white"
                disabled={!message || sending}
                onClick={async () => {
                  if (isQuietHours) {
                    return addToast(formattedQuietHours, {
                      appearance: "error",
                    });
                  }
                  setSending(true);
                  gameCode &&
                    playerName &&
                    (await sendMessage(
                      gameCode,
                      message,
                      playerName,
                      userSessionUUID,
                      undefined,
                      email
                    )
                      .then(({ count }) => {
                        const success =
                          count === 0
                            ? `Message up next`
                            : `Message queued after ${count} message${
                                count > 1 ? "s" : ""
                              }`;
                        addToast(success, {
                          appearance: "success",
                        });
                        setMessage("");
                        setConfetti(true);
                      })
                      .catch((error) => {
                        console.log(error, Object.keys(error));
                        addToast(error.response.data.error, {
                          appearance: "error",
                        });
                      }));
                  setSending(false);
                  setConfetti(false);
                }}
              >
                {sending ? `Sending` : `Send Message`}
              </Button>
            </>
          )}
        </Box>
        <Spacer size={"large"} />
      </Box>
      <Modal
        visible={openEmailModal}
        fullScreenMobile
        onClose={() => {
          return setEmailError(`Please enter an email address`);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <SubTitle2>Please enter your email address to continue.</SubTitle2>
          <Spacer size={"medium"} />
          <Body>
            By submitting, I consent to receiving future news and updates.
          </Body>
          {emailError && (
            <Box>
              <Spacer size={"medium"} />
              <Medium color={colors.poppyRed}>{emailError}</Medium>
            </Box>
          )}
          <Spacer size={"medium"} />
          <Input
            placeholder="Email Address"
            onValueChange={(text) => {
              setEmail(text);
            }}
            value={email}
          ></Input>
          <Spacer size={"medium"} />
          <Button
            buttonType="white"
            disabled={!email}
            onClick={async () => {
              setEmailError("");
              if (!email.includes("@") || !email.includes(".")) {
                return setEmailError(`Please enter a valid email address`);
              }
              email &&
                gameCode &&
                (await captureEmail(
                  email,
                  gameCode,
                  userSessionUUID,
                  playerName
                ).then(() => {
                  setOpenEmailModal(false);
                }));
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};
