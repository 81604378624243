import { useLayoutEffect } from "react";
import { useState } from "react";
import {
  IChannelButtons,
  checkSetupComplete,
  getSubscriptionsForBoard,
} from "../api";
import { ISwatchValue } from "@vestaboard/installables";

export const useSettings = (
  subscriptionId: string | null | undefined,
  subscriptionConfigToken: string | null | undefined,
  boardId: string | null | undefined
) => {
  const [displayOptions, setDisplayOptions] = useState<
    Array<{
      name: string;
      id: string;
      messageSetId?: string;
      installableId: string;
    }>
  >([]);
  const [channels, setChannels] = useState<
    Array<{
      name: string;
      id: string;
      messageSetId?: string;
      installableId: string;
    }>
  >([]);
  const [intervalMinutes, setIntervalMinutes] = useState<string>("");
  const [queueSeconds, setQueueSeconds] = useState<string>("");

  const [captureInfo, setCaptureInfo] = useState<boolean>(false);
  const [allowFreeCompose, setAllowFreeCompose] = useState<boolean>(true);
  const [promoteUrl, setPromoteUrl] = useState<boolean>(true);
  const [allowChannels, setAllowChannels] = useState<boolean>(false);

  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<ISwatchValue>(69);

  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [customCode, setCustomCode] = useState<string>("");
  const [gameCode, setGameCode] = useState<string>("");

  const [channelButtons, setChannelButtons] = useState<IChannelButtons>({
    "1": {
      id: "",
      name: "",
      displayName: "",
      messageSetId: "",
      installableId: "",
    },
  });
  // TODO: options for random guest message set
  useLayoutEffect(() => {
    if (subscriptionId && subscriptionConfigToken && boardId) {
      getSubscriptionsForBoard(
        subscriptionId,
        subscriptionConfigToken,
        boardId
      ).then(({ subscriptions }) => {
        setDisplayOptions(subscriptions);
      });
    }
  }, [subscriptionId, subscriptionConfigToken, setDisplayOptions, boardId]);

  useLayoutEffect(() => {
    subscriptionId &&
      subscriptionConfigToken &&
      checkSetupComplete(subscriptionId, subscriptionConfigToken).then(
        (settings) => {
          if (settings.setupComplete) {
            setSetupComplete(settings.setupComplete);
            setIntervalMinutes(`${settings.intervalMinutes}`);
            setQueueSeconds(`${settings.queueSeconds}`);
            setCaptureInfo(settings.captureInfo);
            setMessage(settings.message);
            setMessageColor(settings.messageColor);
            setCustomCode(settings.customCode);
            settings.channelButtons &&
              settings.channelButtons["1"] &&
              setChannelButtons(settings.channelButtons);
            settings.channelButtons &&
              settings.channelButtons["1"]?.displayName &&
              setAllowChannels(true);
            setCaptureInfo(settings.captureInfo);
            setAllowFreeCompose(settings.allowFreeCompose);
            setGameCode(settings.gameCode);
            setPromoteUrl(settings.promoteUrl);
          }
        }
      );
  }, [
    subscriptionId,
    subscriptionConfigToken,
    setSetupComplete,
    setIntervalMinutes,
    setCaptureInfo,
    setMessage,
    setMessageColor,
    setCustomCode,
    setChannelButtons,
    setAllowChannels,
  ]);

  return {
    subscriptionId,
    subscriptionConfigToken,
    displayOptions,
    setDisplayOptions,
    channelButtons,
    setChannelButtons,
    channels,
    setChannels,
    intervalMinutes,
    setIntervalMinutes,
    queueSeconds,
    setQueueSeconds,
    captureInfo,
    setCaptureInfo,
    message,
    setMessage,
    messageColor,
    setMessageColor,
    setupComplete,
    setSetupComplete,
    customCode,
    setCustomCode,
    allowFreeCompose,
    setAllowFreeCompose,
    promoteUrl,
    setPromoteUrl,
    allowChannels,
    setAllowChannels,
    gameCode,
    // only use for placeholder
    setGameCode
  };
};
