import { useEffect, useState } from "react";
import { getSubscriptionInfo } from "../api";
import {
  IBoardStyle,
  useVestaboardQueryParams,
} from "@vestaboard/installables";

export const useSubscription = () => {
  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();
  const [title, setTitle] = useState("");
  const [boardId, setBoardId] = useState("");
  const [boardName, setBoardName] = useState("");

  const [boardStyle, setBoardStyle] = useState<IBoardStyle>("black");

  useEffect(() => {
    if (subscriptionConfigToken) {
      getSubscriptionInfo(subscriptionConfigToken).then(({ subscription }) => {
        if (subscription?.title) {
          // initialize title with user title if they customized their subscription
          setTitle(subscription?.title);
        }
        const boardId = subscription.board[0].id;
        const subscriptionBoardStyle = subscription.board[0].boardStyle;
        setBoardId(boardId);
        setBoardName(subscription.board[0].title);
        subscriptionBoardStyle && setBoardStyle(subscriptionBoardStyle);
      });
    }
  }, [subscriptionConfigToken]);

  return {
    subscriptionId,
    subscriptionConfigToken,
    title,
    setTitle,
    boardId,
    boardStyle,
    boardName,
    loading: !boardId,
  };
};
