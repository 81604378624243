import { useCallback, useEffect, useState } from "react";
import { getQuietHoursData, getQuietHoursDataForGame } from "../api";

export const useIsQuietHours = ({
  subscriptionId,
  gameCode,
}: {
  subscriptionId?: string | null | undefined;
  gameCode?: string;
}) => {
  const [isQuietHours, setIsQuietHours] = useState(false);
  const [formattedQuietHours, setFormattedQuietHours] = useState("");

  const checkQuietHours = useCallback(async () => {
    if (subscriptionId) {
      const data = await getQuietHoursData(subscriptionId);
      setIsQuietHours(data.isQuietHours);
      setFormattedQuietHours(data.formattedQuietHours);
    } else if (gameCode) {
      const data = await getQuietHoursDataForGame(gameCode);
      setIsQuietHours(data.isQuietHours);
      setFormattedQuietHours(data.formattedQuietHours);
    }
  }, [subscriptionId, gameCode]);

  useEffect(() => {
    // check on load
    checkQuietHours();
  }, [checkQuietHours, subscriptionId, gameCode]);

  useEffect(() => {
    // check every minute
    const interval = setInterval(() => {
      checkQuietHours();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [checkQuietHours]);

  return { isQuietHours, formattedQuietHours };
};
