import { useState } from "react";
import {
  Body,
  Medium,
  Small,
  SubTitle,
  SubTitle2,
} from "@vestaboard/installables/lib/components/Typography";
import {
  ActionButton,
  BoardPreview,
  Button,
  CheckBox,
  Divider,
  Icon,
  Input,
  Modal,
  NotificationBanner,
  Select,
  Spacer,
  SwatchPicker,
  colors,
  useToasts,
} from "@vestaboard/installables";
import {
  IChannelButtons,
  displayNow,
  getUsageData,
  makeGameRoom,
  saveSubscriptionSettings,
} from "../api";
import { useSubscription } from "../hooks/useSubscription";
import { useSettings } from "../hooks/useSettings";
import { SubscriptionPicker } from "../components/SubscriptionPicker";
import { json2csv } from "json-2-csv";
import { Box, CircularProgress, ButtonBase } from "@mui/material";
import { useIsQuietHours } from "../hooks/useQuietHours";
import { makeVestaboardStyles } from '@vestaboard/installables/lib/hooks/useVestaboardStyles';

const useStyles = makeVestaboardStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: '24px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    padding: '16px',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  descriptionContainer: {
    textAlign: "left",
    width: "100%",
    maxWidth: 600,
  },
  addButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const filterCode = (code: string) => {
  return code.replace(/[^a-zA-Z0-9]/g, "");
};

export const Settings = () => {
  const classes = useStyles();

  const [openPreview, setOpenPreview] = useState(false);
  const [openAreYouSure, setOpenAreYouSure] = useState(false);
  const [changingVanityUrl, setChangingVanityUrl] = useState(false);

  const {
    boardId,
    boardName,
    subscriptionConfigToken,
    subscriptionId,
    boardStyle,
    loading,
  } = useSubscription();
  const {
    displayOptions,
    intervalMinutes,
    setIntervalMinutes,
    queueSeconds,
    setQueueSeconds,
    setupComplete,
    setSetupComplete,
    message,
    setMessage,
    messageColor,
    setMessageColor,
    customCode,
    setCustomCode,
    channelButtons,
    setChannelButtons,
    captureInfo,
    setCaptureInfo,
    allowFreeCompose,
    setAllowFreeCompose,
    promoteUrl,
    setPromoteUrl,
    allowChannels,
    setAllowChannels,
    gameCode,
    setGameCode,
  } = useSettings(subscriptionId, subscriptionConfigToken, boardId);
  const { isQuietHours, formattedQuietHours } = useIsQuietHours({
    subscriptionId,
  });
  // const [numberOfChannelButtons, setNumberOfChannelButtons] = useState(0)
  const [gameCodeError, setGameCodeError] = useState("");
  // TODO: reenable message set defaults when decided
  // const { messageSets } = useMessageSets();
  const { addToast } = useToasts();

  if (loading) {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.descriptionContainer}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  const saveSettings = async () => {
    if (subscriptionId && subscriptionConfigToken) {
      try {
        setGameCodeError("");
        const gameResponse = await makeGameRoom(
          subscriptionId,
          customCode ? customCode.toUpperCase() : undefined
        );
        const { gameCode, gameUrl } = gameResponse;

        // only use channel buttons with id
        const channelButtonsWithId = Object.keys(channelButtons).reduce(
          (acc, key) => {
            const channelButton = channelButtons[key as "1" | "2" | "3"];
            return channelButton?.id
              ? {
                  ...acc,
                  [key]: channelButton,
                }
              : acc;
          },
          {}
        ) as IChannelButtons;

        await saveSubscriptionSettings({
          subscriptionId,
          subscriptionConfigToken,
          intervalMinutes,
          gameCode,
          gameUrl,
          boardId,
          boardName,
          message,
          messageColor,
          customCode,
          channelButtons: channelButtonsWithId,
          captureInfo,
          allowFreeCompose,
          promoteUrl,
          queueSeconds,
        });
        // SAVE GUEST SEND MESSAGE
        setSetupComplete(true);
        addToast(`Guest Send Settings Updated`, {
          appearance: "success",
        });
        setGameCode(gameCode);
      } catch (e: any) {
        const error = e.toJSON();
        if (error.status === 409) {
          scrollToTop();
          return setGameCodeError(
            "This URL has already been claimed. Please pick a different vanity URL."
          );
        }
        addToast(`Error updating settings`, { appearance: "error" });
      }
    }
  };

  return (
    <>
      <Box sx={classes.content}>
        <Box sx={classes.descriptionContainer}>
          <NotificationBanner
            text={formattedQuietHours}
            visible={isQuietHours}
          />
          <SubTitle>Description</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Allow guests an opportunity to see Vestaboard update with their own
            message.
          </Body>
          <Spacer size={"medium"} />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <ActionButton
              onClick={() => {
                setOpenPreview(true);
              }}
            >
              Preview
            </ActionButton>
            <div style={{ width: 16 }}></div>
            <ActionButton
              disabled={!setupComplete}
              onClick={async () => {
                if (isQuietHours) {
                  return addToast(formattedQuietHours, {
                    appearance: "error",
                  });
                }
                subscriptionId &&
                  subscriptionConfigToken &&
                  (await displayNow(subscriptionId, subscriptionConfigToken));
                await addToast(`Message Sent`, { appearance: "success" });
              }}
            >
              Display Now
            </ActionButton>
          </Box>
          <Spacer size={"medium"} />
          <Divider />
        </Box>
        <Box sx={classes.section}>
          <Spacer size="medium" />
          <SubTitle>Call to Action</SubTitle>
          <Spacer size={"medium"} />
          <SubTitle2>Header</SubTitle2>
          <Spacer size={"medium"} />
          <Input
            placeholder="Want me to update?"
            onValueChange={(text) =>
              text.length <= 40
                ? setMessage(text)
                : addToast("CTA must be less than 40 characters", {
                    appearance: "error",
                  })
            }
            value={message}
            max="40"
          ></Input>
          <Spacer size={"medium"} />
          <Spacer size={"medium"} />
          <SubTitle2>URL Code</SubTitle2>
          {gameCodeError && (
            <>
              <Spacer size={"small"} />
              <Medium color={colors.poppyRed}>{gameCodeError}</Medium>
            </>
          )}
          <Spacer size={"medium"} />
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Medium>https://vb.plus/</Medium>
            <Input
              placeholder={gameCode ? gameCode : "yourcode"}
              onValueChange={(text) => {
                const filteredText = filterCode(text);
                filteredText.length <= 12 && setCustomCode(filteredText);
                if (customCode) {
                  setChangingVanityUrl(true);
                }
              }}
              value={customCode}
              max="12"
            />
            <ButtonBase
              style={{ paddingLeft: 8 }}
              onClick={async () => {
                navigator.clipboard.writeText(
                  customCode
                    ? `https://vb.plus/${customCode}`
                    : `https://vb.plus/${gameCode}`
                );
                await addToast(`Copied the URL to your clipboard`, {
                  appearance: "success",
                });
              }}
            >
              <Icon type="copy" />
            </ButtonBase>
          </Box>
          <Spacer size={"medium"} />
          <Box style={{ textAlign: "center" }}>
            <Small>
              Designate your own URL if you plan to promote it online or via QR
              code. If you leave blank, a new URL Code will generate daily.
            </Small>
          </Box>
          <Spacer size={"medium"} />
          <SubTitle2>Line Separator Color</SubTitle2>
          <Spacer size={"medium"} />
          <SwatchPicker
            value={messageColor}
            setValue={setMessageColor}
            hideEditTools={true}
            spaceEvenly={true}
            endPaintMode={() => {}}
            isPaintMode={false}
            rainbowSwatch={true}
          />
          <Spacer size={"medium"} />
          <Box style={{ textAlign: "center" }}>
            <Small>
              Choose the color of the line separator shown below the header text
            </Small>
          </Box>
        </Box>
        <Box sx={classes.section}>
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle>Send Options</SubTitle>
          <Spacer size="medium" />
          <Spacer size={"medium"} />
          <CheckBox
            checked={allowFreeCompose}
            label={"Allow guests to compose their own message"}
            onValueChange={(checked: boolean) => {
              setAllowFreeCompose(checked);
            }}
          />
          {allowFreeCompose && (
            <CheckBox
              checked={promoteUrl}
              label={"Show URL at the bottom of shorter composed messages"}
              onValueChange={(checked: boolean) => {
                setPromoteUrl(checked);
              }}
            />
          )}
          <CheckBox
            checked={allowChannels}
            label={"Allow guests to display content from my channel(s)"}
            onValueChange={(checked: boolean) => {
              if (!checked) {
                setChannelButtons({});
              }
              setAllowChannels(checked);
            }}
          />
          <Spacer size="medium" />
          {allowChannels &&
            channelButtons &&
            Object.keys(channelButtons).map((_, index) => (
              <>
                <SubscriptionPicker
                  number={(index + 1) as 1 | 2 | 3}
                  displayOptions={displayOptions}
                  selectedOptions={channelButtons}
                  setSelectedOptions={(options) => {
                    setChannelButtons(options);
                  }}
                />
              </>
            ))}

          {allowChannels &&
            (!channelButtons || Object.keys(channelButtons).length < 3) && (
              <Box sx={classes.addButton}>
                <Button
                  buttonType="outline"
                  onClick={function (): void {
                    const currentKeys = Object.keys(channelButtons) || [];
                    setChannelButtons({
                      ...channelButtons,
                      [currentKeys.length + 1]: {
                        displayName: "",
                        channel: "",
                        name: "",
                        messageSetId: "",
                        installableId: "",
                      },
                    });
                  }}
                >
                  Add another channel option
                </Button>
              </Box>
            )}
          <Spacer size={"large"} />
          <SubTitle2>Data Capture</SubTitle2>
          <Spacer size={"medium"} />
          <CheckBox
            checked={captureInfo}
            label={"Capture email address of sender"}
            onValueChange={(checked: boolean) => {
              setCaptureInfo(checked);
            }}
          />
          <Spacer size={"medium"} />
          {captureInfo && (
            <Button
              buttonType="outline"
              disabled={!captureInfo}
              onClick={async () => {
                if (subscriptionId && subscriptionConfigToken) {
                  const data = await getUsageData(
                    subscriptionId,
                    subscriptionConfigToken
                  );
                  const csv = await json2csv(data, {});
                  const link = document.createElement("a");
                  link.href = "data:text/csv," + encodeURIComponent(csv);
                  link.download = "guest-senders.csv";
                  link.click();
                }
              }}
            >
              {"Download Usage Data"}
            </Button>
          )}
        </Box>
        <Box sx={classes.section}>
          <Divider />
          <Spacer size="medium" />
          <SubTitle>Frequency</SubTitle>
          <Spacer size={"medium"} />
          <Select
            options={[
              {
                id: "0",
                name: "No Frequency",
              },
              {
                id: "1",
                name: "Minute",
              },
              {
                id: "5",
                name: "5 Minutes",
              },
              {
                id: "10",
                name: "10 Minutes",
              },
              {
                id: "15",
                name: "15 Minutes",
              },
              {
                id: "20",
                name: "20 Minutes",
              },
              {
                id: "30",
                name: "30 Minutes",
              },
              {
                id: "60",
                name: "Hour",
              },
            ]}
            value={intervalMinutes}
            onValueChange={setIntervalMinutes}
            label="Show URL when Vestaboard has been inactive for"
          />
          <Spacer size={"large"} />
          <Select
            options={[
              {
                id: "30",
                name: "30 Seconds",
              },
              {
                id: "60",
                name: "1 Minute",
              },
              {
                id: "120",
                name: "2 Minutes",
              },
              {
                id: "300",
                name: "5 Minutes",
              },
            ]}
            value={queueSeconds}
            onValueChange={setQueueSeconds}
            label="Time Between Messages (if multiple sent at once)"
          />
          <Spacer size={"large"} />
          <Spacer size={"large"} />
          <Button
            buttonType="white"
            disabled={!intervalMinutes}
            onClick={async () => {
              if (changingVanityUrl) {
                setOpenAreYouSure(true);
              } else {
                await saveSettings();
              }
            }}
          >
            {"Save Changes"}
          </Button>
          <Spacer size={"large"} />
        </Box>
        <Spacer size={"large"} />
      </Box>
      <Modal
        visible={openAreYouSure}
        fullScreenMobile
        onClose={() => {
          setOpenAreYouSure(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <SubTitle2>
            Are you sure you want to release your previous URL?
          </SubTitle2>
          <Spacer size={"medium"} />
          <Spacer size={"medium"} />
          <Button
            buttonType="white"
            onClick={async () => {
              await saveSettings();
              setChangingVanityUrl(false);
              setOpenAreYouSure(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </Modal>
      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            boardStyle={boardStyle}
            characters={[
              [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0,
              ],
              [
                0, 0, 23, 1, 14, 20, 0, 13, 5, 0, 20, 15, 0, 21, 16, 4, 1, 20,
                5, 60, 0, 0,
              ],
              [
                0, 0, 63, 64, 65, 66, 67, 68, 63, 64, 65, 66, 67, 68, 0, 0, 0,
                0, 0, 0, 0, 0,
              ],
              [
                0, 0, 19, 5, 14, 4, 0, 1, 0, 13, 5, 19, 19, 1, 7, 5, 0, 1, 20,
                0, 0, 0,
              ],
              [
                0, 0, 22, 2, 56, 16, 12, 21, 19, 59, 24, 24, 24, 24, 0, 0, 0, 0,
                0, 0, 0, 0,
              ],
              [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0,
              ],
            ]}
          />
        </Box>
      </Modal>
    </>
  );
};
